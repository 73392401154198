.mark-points {
    padding: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .results {
    text-align: center;
  }
  
  .image-blocks {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .image-block {
    position: relative;
    cursor: pointer;
  }
  
  .image-container {
    position: absolute;
  }
  
  .image-container img {
    max-width: 100%;
    height: auto;
  }
  
  .point-marker {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: yellow;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }

  
  .center-container {
    display: flex;
    flex-direction: column; /* or row depending on your layout */
    align-items: center;
    justify-content: center;
  }
  
  .search-box {
    width: 300px;
    padding: 10px;
    margin-bottom: 10px; /* Adjust the spacing between the input and button */
  }
  
  .search-button {
    padding: 10px 20px;
    cursor: pointer;
  }
  