body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.main-layout {
  display: flex;
}

.content {
  flex: 1;
  padding: 20px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




.image-container {
  position: relative;
  display: inline-block;
}

.image {
  width: 100%; /* Ensure the image is responsive */
  height: auto;
}

.marker {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: red;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

div > label {
  margin-right: 10px;
}

button {
  margin-left: 10px;
}

.App {
  text-align: center;
}

h1 {
  margin: 20px 0;
}

.App {
  text-align: center;
}

img {
  max-width: 100%;
  height: auto;
}

input {
  margin: 5px;
}
.center-container {
  display: flex;
  flex-direction: column; /* or row depending on your layout */
  align-items: center;
  justify-content: center;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.print-button {
  padding: 10px 20px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
}

.print-button:hover {
  background-color: #0056b3;
}

.printme { display: none;}
@media print { 
    .no-printme  { display: none;}
    .printme  { display: block;}
}


.print-button {
  padding: 10px 20px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
}

.print-button:hover {
  background-color: #0056b3;
}