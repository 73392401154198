.bottom-menu {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #f8f8f8;
    padding: 10px 0;
  }
  
  .bottom-menu ul {
    display: flex;
    justify-content: space-around;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .bottom-menu a {
    text-decoration: none;
    color: #333;
  }
  