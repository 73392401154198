.home {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 80px); /* Adjust for header and bottom menu */
  }
  
  .center-image {
    width: 200px;
    height: auto;
    cursor: pointer;
  }
  